$(function () {
    const $gdprWrapper = $('#gdpr-wrapper')
    const storageItem = localStorage.getItem('gdpr');

    if (storageItem) {
        const storageItemObject = JSON.parse(storageItem)
        const expired = storageItemObject.expiry
        const today = new Date()
        if (today.getTime() > expired) {
            localStorage.removeItem('gdpr')
            $gdprWrapper.show()
            attachGdprEvent()
        }
    } else {
        $gdprWrapper.show()
        attachGdprEvent()
    }

    function attachGdprEvent() {
        $('#gdpr-button').on('click', function () {
            const now = new Date()
            let expireDate = new Date()
            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const gdprItem = {
                expiry: expireDate.setDate(now.getDate() + 7)
            }
            console.log(gdprItem)
            localStorage.setItem('gdpr', JSON.stringify(gdprItem))
            $gdprWrapper.hide(1000);
        })
    }
})
